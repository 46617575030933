<template>

  <div class="classmate-content-cell">

    <div style="margin: auto;">
      <van-image :src="message.url" radius="15px" lazy-load fit="cover"
                 class="classmate-img" v-if="message.type === 'IMAGE'">
        <template v-slot:loading>
          <van-loading type="spinner" size="20"/>
        </template>
        <template v-slot:error>
          加载失败
        </template>
      </van-image>
    </div>


    <div class="classmate-content-text" v-if="message.type === 'TEXT'">
      <br/>
      <div style="margin-left: 20px; font-size: 14px;">{{ message.content }}</div>
      <br/>
      <br/>
    </div>

    <div v-if="message.type === 'VIDEO'" style="width: 90%; margin-left: 20px; margin-top: 20px;">
      <video :src="message.url" id="video" style="width: 100%; height: auto; -webkit-border-radius: 10px;" :poster="require('@/assets/poster.jpg')"
             preload="true" x-webkit-airplay="true" webkit-playsinline="true" playsinline="true" x5-video-player-type="h5" controls = "false"></video>
    </div>

    <button class="classmate-audio-content" v-if="message.type === 'AUDIO-PLAY'">
      <div style="margin-left: 15px; font-size: 18px;">
        <svg class="icon" id="Layer_1" aria-hidden="true"  viewBox="0 0 24 30" style="color: #fefdfb; margin-top: 10px; margin-bottom: 5px;" v-for="item in svgList" :key="item">
          <rect x="0" y="13" width="4" height="18" fill="#fefdfb">
            <animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s" repeatCount="indefinite"></animate>
            <animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite"></animate>
          </rect>
          <rect x="10" y="13" width="4" height="10" fill="#fefdfb">
            <animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate>
            <animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate>
          </rect>
          <rect x="20" y="13" width="4" height="10" fill="#fefdfb">
            <animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate>
            <animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate>
          </rect>
        </svg>
        <span style="color: white; position: absolute; margin-left: 5px; margin-top: 11px; width: 20px; height: 20px; font-size: 14px;">{{ message.duration }}s</span>
      </div>
    </button>

    <button class="classmate-audio-content" v-if="message.type === 'AUDIO-STOP'">
      <div style="margin-left: 15px; font-size: 18px;">
        <svg class="icon" aria-hidden="true" style="color: #fefdfb; margin-top: 10px; margin-bottom: 5px;" v-for="item in svgList" :key="item">
          <use xlink:href="#iconaudiospectrum"></use>
        </svg>
        <span style="color: white; position: absolute; margin-left: 5px; margin-top: 11px; width: 40px; height: 20px; font-size: 14px;">{{ message.duration }}s</span>
      </div>
    </button>


  </div>
</template>

<script>
import Vue from 'vue';
import {Lazyload} from 'vant'
import $ from "jquery";

Vue.use(Lazyload)
export default {
  name: "ClassmateContentCell",
  props:['message'],
  data() {
    return {
      svgList: [],
    }
  },
  created() {
    this.initSvg()
  },
  computed: {
    playsinline () {
      let ua = navigator.userAgent.toLocaleLowerCase()
      // x5内核
      if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
        return false
      } else {
        // ios端
        return true
      }
    }
  },
  methods: {
    initSvg() {
      for (let i = 0; i < 3; i++) {
        this.svgList.push(this.svgList.length + 1)
      }
    },
    onPlayerCanplay (player) {
      console.log('player Canplay!', player)
      const ua = navigator.userAgent.toLocaleLowerCase();
      // x5内核
      if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
        $('body').find('video').attr('x-webkit-airplay', true).attr('x5-playsinline', true).attr('webkit-playsinline', true).attr('playsinline', true).attr('x5-video-player-fullscreen', true)
      } else {
        // ios端
        $('body').find('video').attr('webkit-playsinline', 'true').attr('playsinline', 'true').attr('x5-video-player-fullscreen', 'true')
      }
    }
  }
}
</script>

<style scoped>

.classmate-content-cell {
  min-height: 40px;
  background: #fefdfb;
}

.classmate-img {
  /*margin-top: 10px;*/
  margin-left: 10px;
  margin-right: 10px;
  min-width: 200px;
  border: medium solid #68d0de;
  -webkit-border-radius: 20px;
}

.classmate-content-text {
  margin-top: 40px;
  text-align: left;
  margin-right: 10px;
  margin-left: 10px;
  padding-right: 10px;
  border: medium solid #68d0de;
  -webkit-border-radius: 20px;
}

.classmate-user {
  position: relative;
  float: right;
  z-index: 998;
  background: #fefdfb;
  height: 50px;
  width: 150px;
  margin-right: 20px;
  margin-top: -35px;
  margin-bottom: 20px;
  -webkit-box-shadow: 0 3px 20px rgba(9, 9, 9, 0.3);
  -webkit-border-radius: 10px;
  -moz-box-shadow: 0 3px 20px rgba(9, 9, 9, 0.3);
  box-shadow: 0 3px 20px rgba(9, 9, 9, 0.3);
  /*background: #EEFF99;*/
}

.classmate-user-video {
  position: relative;
  float: right;
  z-index: 998;
  background: #fefdfb;
  height: 50px;
  width: 150px;
  margin-right: 20px;
  margin-top: -10px;
  margin-bottom: 20px;
  -webkit-box-shadow: 0 3px 20px rgba(9, 9, 9, 0.3);
  -webkit-border-radius: 10px;
  -moz-box-shadow: 0 3px 20px rgba(9, 9, 9, 0.3);
  box-shadow: 0 3px 20px rgba(9, 9, 9, 0.3);
  /*background: #EEFF99;*/
}

.classmate-user-headImg {
  float: left;
  position: relative;
  margin-top: 8px;
  margin-left: 10px;
  min-width: 30px;
  min-height: 30px;
}

.classmate-user-name {
  position: absolute;
  text-align: left;
  margin: 5px 50px;
  width: 140px;
  font-size: 14px;
  color: #999999
}

.classmate-content-time {
  position: absolute;
  left: 20px;
  margin: 25px 30px;
  font-size: 10px;
  color: #999999
}

.classmate-audio-content {
  width: 90%;
  margin-top: 20px;
  height: 70px;
  text-align: left;
  margin-right: 10px;
  margin-left: 10px;
  background: #68d0de;
  border: none;
  -webkit-border-radius: 20px;
}
</style>
