<template>
  <div id="content" style="background: #fefdfb">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" style="margin-top: 60px; margin-bottom: 60px">
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="我也是有底线的"
          :immediate-check="false"
          :offset="10"
          @load="onLoad"
          style="min-height: 500px"
      >

        <div style="margin-top: 10px; padding-bottom: 20px">
          <div v-for="(message) in messageList" :key="message.contentId">
            <classmate-content-cell :message="message" :key="message.contentId" @click.native="preview(message, messageList)"></classmate-content-cell>
            <div class="classmate-user" v-if="message.type !== 'VIDEO'">
              <van-image class="classmate-user-headImg" width="30"
                         :src="message.headImage">
                <template v-slot:loading>
                  <van-loading type="spinner" size="20"/>
                </template>
                <template v-slot:error>
                </template>
              </van-image>
              <div class="classmate-user-name">{{ message.name }}</div>
              <div class="classmate-content-time">{{ message.time }}</div>
              <van-button @click="recall(message.contentId)" color="white" size="mini" style="left:23%; top: 17%">
                <van-icon :name="require('@/assets/icon/trash.svg')" size="20px"></van-icon>
              </van-button>
              <br/>
              <br/>
            </div>

            <div class="classmate-user-video" v-if="message.type === 'VIDEO'">
              <van-image class="classmate-user-headImg" width="30"
                         :src="message.headImage">
                <template v-slot:loading>
                  <van-loading type="spinner" size="20"/>
                </template>
                <template v-slot:error>
                </template>
              </van-image>
              <div class="classmate-user-name">{{ message.name }}</div>
              <div class="classmate-content-time">{{ message.time }}</div>
              <van-button @click="recall(message.contentId)" color="white" size="mini" style="left:23%; top: 17%">
                <van-icon :name="require('@/assets/icon/trash.svg')" size="20px"></van-icon>
              </van-button>
              <br/>
              <br/>
              <br/>
            </div>
            <br/>
          </div>
          <br/>



<!--        <classmate-content-cell v-for="(message) in messageList" :message="message"-->
<!--                                :key="message.contentId" @click.native="preview(message, messageList)"></classmate-content-cell>-->
        </div>
      </van-list>

    </van-pull-refresh>
  </div>
</template>

<script>
import Vue from 'vue';
import {Dialog, List} from 'vant';
import {Cell, CellGroup} from 'vant';
import {PullRefresh} from 'vant';
import {Image as VanImage} from 'vant';
import {Loading} from 'vant';
import {Empty} from 'vant';
import {Sticky} from 'vant';
import ClassmateContentCell from "@/components/ClassmateContentCell";
import classmateApi from "@/api/classmate";
import strings from "@/utils/string";
import wx from "weixin-js-sdk";
import { Toast } from 'vant';

Vue.use(Toast);
Vue.use(Sticky);
Vue.use(Empty);
Vue.use(Loading);
Vue.use(VanImage);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(Cell);
Vue.use(CellGroup);

export default {
  name: "WechatContent",
  components: {ClassmateContentCell},
  props: {
    spaceId: String
  },
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      pageIndex: 1,
      pageSize: 10,
      contentTime: '2020-11-20',
      messageList: [],
      amr: null,
      amrTime: null,
      timer: null,
      playingUrl: ''
    };
  },
  mounted() {
    this.onRefresh()
  },
  methods: {
    play(message) {
      if (this.playingUrl === message.url) {
        this.stopAllAudio()
        return
      }

      this.playingUrl = message.url
      console.log(message.url)
      let BenzAMRRecorder = require('benz-amr-recorder');  //引入
      let self = this;
      this.stopAllAudio()
      this.amr = null;
      this.amr = new BenzAMRRecorder();
      this.amr.initWithUrl(message.url).then(function () {
        self.amr.play()
        message.type = 'AUDIO-PLAY'
      });   //初始化
      this.amr.onEnded(function () {
        self.playingUrl = ''
        clearInterval(self.timer)
        self.stopAllAudio()
      })
      this.amr.onAutoEnded(function () {
        self.playingUrl = ''
        clearInterval(self.timer)
        self.stopAllAudio()
      })
    },
    preview(message, messageList) {
      if (message.type.indexOf('AUDIO') !== -1) {
        this.play(message)
        return
      }

    if (message.type !== 'IMAGE') {
        return
      }

      let imageArr = []
      for (let i in messageList) {
        if (messageList[i].type === 'IMAGE') {
          imageArr.push(messageList[i].url)
        }
      }

      wx.previewImage({
        current: message.url,
        urls: imageArr
      })

    },
    stopAllAudio() {
      for (const i in this.messageList) {
        if (this.messageList[i].type.indexOf('AUDIO') !== -1) {
          this.messageList[i].type = 'AUDIO-STOP'
        }
      }
      if (this.amr != null && this.amr.isPlaying()) {
        this.amr.stop()
      }
    },
    onLoad() {
      let self = this
      if (strings.isBlank(this.spaceId)) {
        this.loading = false;
        this.finished = true;
        return
      }
      classmateApi.getContentByPage({
        spaceId: this.spaceId,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }).then(resp => {
        self.loading = false;
        if (resp != null && resp.code === 200) {
          let result = resp.data.records;

          self.pageIndex ++ ;

          if (self.refreshing) {
            // 如果是刷新，清空列表
            self.messageList = [];
            self.refreshing = false;
          }

          if (result == null || result.length === 0) {
            self.finished = true;
            return
          }

          if (self.messageList.length === resp.data.total) {
            self.finished = true
          }

          for (let i in result) {
            self.messageList.push({
              contentId: result[i].id,
              content: result[i].content,
              time: result[i].createTime.substring(0, 10),
              name: result[i].createUserName,
              headImage: result[i].headImage,
              type: result[i].type,
              url: result[i].url,
              duration: result[i].duration
            })
          }
          self.stopAllAudio()
        } else {
          self.loading = false;
          self.finished = true;
        }
      }).catch(err => {
        this.loading = false;
        this.finished = true;
        alert(err)
      })
    },
    onRefresh() {
      // 重新加载数据
      this.refreshing = true;
      this.loading = true;
      this.finished = false;
      this.pageIndex = 1;
      this.onLoad();
    },
    recall(contentId) {
      let self = this;
      console.log('recall')
      // eslint-disable-next-line no-undef
      Dialog.confirm({
        title: '删除回忆',
        message: '删除回忆后不可恢复，请谨慎操作\n回忆留存30天后不可删除',
      }).then(() => {
        const data = {
          id: contentId
        }
        classmateApi.recall(data).then(resp => {
          if (resp != null && resp.code === 200) {
            self.onRefresh()
          } else {
            Toast.fail(resp.message)
          }
        })
      }).catch(() => {
        // 什么都不做
      })
    },

  },
}
</script>

<style scoped>

.classmate-user {
  position: relative;
  float: right;
  z-index: 998;
  background: #fefdfb;
  height: 50px;
  width: 150px;
  margin-right: 20px;
  margin-top: -35px;
  margin-bottom: 20px;
  -webkit-box-shadow: 0 3px 20px rgba(9, 9, 9, 0.3);
  -webkit-border-radius: 10px;
  -moz-box-shadow: 0 3px 20px rgba(9, 9, 9, 0.3);
  box-shadow: 0 3px 20px rgba(9, 9, 9, 0.3);
  /*background: #EEFF99;*/
}

.classmate-user-video {
  position: relative;
  float: right;
  z-index: 998;
  background: #fefdfb;
  height: 50px;
  width: 150px;
  margin-right: 20px;
  margin-top: -10px;
  margin-bottom: 20px;
  -webkit-box-shadow: 0 3px 20px rgba(9, 9, 9, 0.3);
  -webkit-border-radius: 10px;
  -moz-box-shadow: 0 3px 20px rgba(9, 9, 9, 0.3);
  box-shadow: 0 3px 20px rgba(9, 9, 9, 0.3);
  /*background: #EEFF99;*/
}

.classmate-user-headImg {
  float: left;
  position: relative;
  margin-top: 8px;
  margin-left: 10px;
  min-width: 30px;
  min-height: 30px;
}

.classmate-user-name {
  position: absolute;
  text-align: left;
  margin: 5px 50px;
  width: 140px;
  font-size: 14px;
  color: #999999
}

.classmate-content-time {
  position: absolute;
  left: 20px;
  margin: 25px 30px;
  font-size: 10px;
  color: #999999
}
</style>
