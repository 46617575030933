const strings = {

}

strings.isBlank = str => {
    if (null == str) {
        return true
    }

    return Object.keys(str).length === 0
}

export default strings
