<template>
  <div class="home">
    <van-nav-bar
        left-arrow
        left-text="共同回忆"
        @click-left="closeWindow"
        :fixed="true"
        z-index="999" class="classmate-tab-bar">
      <template #right>
        <van-icon :name="require('@/assets/icon/logo@3x.png')" size="80" />
      </template>
    </van-nav-bar>
    <classmate-content :space-id="spaceId" v-if="this.isWechatBrowser"/>

    <van-tabbar active-color="#646566" z-index="999" class="classmate-tabbar" v-if="this.isWechatBrowser">
      <van-tabbar-item replace :to="'/write?id='+this.spaceId" icon="edit">写下感想</van-tabbar-item>
      <van-tabbar-item replace :to="'/photo?id='+this.spaceId" icon="photo-o">留影</van-tabbar-item>
      <van-tabbar-item replace :to="'/myAudio?id='+this.spaceId" :icon="require('@/assets/icon/mic.svg')">
        <span>留声</span>
      </van-tabbar-item>
      <van-tabbar-item replace :to="'/video?id='+this.spaceId" icon="video-o">留下时光</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
// @ is an alias to /src
import ClassmateContent from "@/components/ClassmateContent";
import {NavBar, Tabbar, TabbarItem} from 'vant'
import Vue from 'vue'
import wx from 'weixin-js-sdk'
import WechatUtils from "@/components/WechatUtils";
import strings from "@/utils/string";
import {Image as VanImage} from 'vant';
import {baseUrl} from "@/utils/request";
import {isIosDevice, isWechatBrowser} from "@/utils/web";

Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(NavBar);
Vue.use(VanImage)

export default {
  name: 'Home',
  components: {
    // HelloWorld
    ClassmateContent
  },
  methods: {
    closeWindow() {
      wx.closeWindow()
    }
  },
  data() {
    return {
      active: 0,
      spaceId: '',
      isWechatBrowser: false
    }
  },
  beforeRouteEnter (to, from, next) {
    let isiOS = isIosDevice()
    // XXX: 修复iOS版微信HTML5 History兼容性问题
    if (isiOS && to.path !== location.pathname) {
      // 此处不可使用location.replace
      location.assign(to.fullPath)
    } else {
      next()
    }
  },
  created() {
    this.isWechatBrowser = isWechatBrowser()
    if (!this.isWechatBrowser) {
      // 加载
      this.$router.replace('/index')
    } else {
      this.spaceId = this.$route.query.id

      if (strings.isBlank(this.spaceId)) {
        this.$toast.loading({
          duration: 0,
          message: '微信认证中',
          forbidClick: true
        })
        window.location.href = baseUrl + "/wechat/redirect"

        this.$toast.clear()
        return
      }

      WechatUtils.initSpaceInfo([
        'playVoice',
        'stopVoice',
        'onVoicePlayEnd'
      ])
    }
  },
  mounted() {

  }
}
</script>

<style scoped>


</style>
