<template>
  <div class="myAudio">
    <van-nav-bar
        left-arrow
        left-text="留声"
        @click-left="goBack"
        :fixed="true"
        class="classmate-tab-bar"
    >
      <template #right>
        <van-icon :name="require('@/assets/icon/logo@3x.png')" size="80" />
      </template>
    </van-nav-bar>
    <p class="classmate-upload-tips" style="margin-top: 60px;">每段音频不能超过60秒</p>

    <div style="margin: 0 auto; text-align: center; border: 2px solid #68d0de; border-radius: 122px; width: 244px; height: 244px;">
    <div style="margin: 8px auto 0; text-align: center; border: 2px solid #68d0de; border-radius: 111px; width: 222px; height: 222px;">
    <div style="margin: 8px auto 0;text-align: center; border: 2px solid #68d0de;border-radius: 100px; width: 200px; height: 200px;">
      <van-button round type="info" v-if="this.content !== ''" @click="remove" color="white" class="classmate-audio-remove-button" :icon="require('@/assets/icon/cross.svg')" >
      </van-button>
      <van-button round type="info" class="classmate-audio-upload-button" icon="getIcon" @click="onRecordClick">
        <van-icon :name="require('@/assets/icon/audio-mic.svg')" size="20px" v-if="this.recordStatus === this.INIT" color="white">
        </van-icon>
        <van-icon name="stop" size="20px" v-if="this.recordStatus === this.RECORDING"></van-icon>
        <van-icon name="play" size="20px" v-if="this.recordStatus === this.RECORDED"></van-icon>
        <van-icon name="stop" size="20px" v-if="this.recordStatus === this.PLAYING"></van-icon>
        <br>
        {{ buttonText }}秒
      </van-button>
    </div>
    </div>
    </div>


    <br>
    <van-button type="primary" color="#68d0de" class="classmate-commit-button" @click="submit">提 交 留 声</van-button>
  </div>
</template>

<script>
import {getBaseUrl, getUrlParam, isIosDevice} from "@/utils/web";
import {Button, Dialog} from 'vant';
import Vue from 'vue';
import wx from "weixin-js-sdk";
import strings from "@/utils/string";
import { CountDown } from 'vant';
import classmateApi from "@/api/classmate";

Vue.use(CountDown);
Vue.use(Button);

export default {
  name: "myAudio",
  data() {
    return {
      INIT: 'INIT',
      RECORDING: 'RECORDING',
      RECORDED: 'RECORDED',
      PLAYING: 'PLAYING',
      // 是否已经记录
      recorded: false,
      recording: false,
      buttonText: 0,
      recordTime: 0,
      recordStatus: 'INIT',
      content: '',
      timer: null,
      spaceId: 0
    }
  },
  methods: {
    goBack() {
      if (!strings.isBlank(this.content)) {
        this.$dialog.confirm({
          message: '你的录音还没有上传，是否离开？'
        }).then(() => {
          this.$router.replace({
            path:'/',
            query: {
              id: this.$route.query.id
            }
          })
        })
        return
      }

      // eslint-disable-next-line no-debugger
      if (this.recording === true) {
        this.$toast.fail( {
          message: '声音正在录制中'
        })
        return
      }


      this.$router.replace({
        path:'/',
        query: {
          id: this.$route.query.id
        }
      })
    },
    // 开始录音
    startRecord() {
      let self = this
      wx.startRecord({
        success: function () {
          self.recording = true;
          self.setStatus(self.RECORDING)
          self.timer = setInterval(() => {
            self.buttonText ++;
          }, 1000)
          wx.onVoiceRecordEnd({
            complete: function (res) {
              self.recording = false;
              self.content = res.localId;
              self.recordTime = self.buttonText
              clearInterval(self.timer)
              self.setStatus(self.RECORDED)
            }
          });
        },
        cancel: function () {
          console.log('用户拒绝授权')
        }
      });
    },
    remove() {
      this.$dialog.confirm({
        message: '你的录音还没有上传，是否删除？'
      }).then(() => {
        this.setStatus(this.INIT)
        this.recordTime = 0
        clearInterval(this.timer)
        this.content = ''
        this.buttonText = 0
      })
    },
    // 停止录音
    stopRecord() {
      let self = this
      wx.stopRecord({
        success: function (res) {
          self.content = res.localId;
          self.recordTime = self.buttonText;
          self.recording = false;
          clearInterval(self.timer)
          self.setStatus(self.RECORDED)
        },
        fail: function (err) {
          console.log(err)
          self.setStatus(self.INIT)
        }
      })
    },
    // 播放录音
    playVoice: function () {
      this.buttonText = 0
      clearInterval(this.timer)
      this.setStatus(this.PLAYING)
      let self = this
      wx.playVoice({
        localId: this.content,
        success: function () {
          self.timer = setInterval(() => {
            self.buttonText++;
          }, 1000)
          wx.onVoicePlayEnd({
            success: function (res) {
              console.log(res)
              clearInterval(self.timer)
              self.buttonText = self.recordTime
              self.setStatus(self.RECORDED)
            }
          })
        }
      })
    },
    // 停止播放
    stopVoice() {
      this.setStatus(this.RECORDED)
      clearInterval(this.timer)
      this.buttonText = this.recordTime
      let self = this
      wx.stopVoice({
        localId: self.content
      })
    },
    // 点击按钮
    onRecordClick() {
      if (this.recordStatus === this.INIT) {
        // 开始录音
        this.startRecord()
        return
      }

      if (this.recordStatus === this.RECORDING) {
        // 结束录音
        this.stopRecord()
        return
      }

      if (this.recordStatus === this.RECORDED) {
        // 播放录音
        this.playVoice()
        return
      }

      if (this.recordStatus === this.PLAYING) {
        // 停止播放
        this.stopVoice()
      }
    },
    setStatus(status) {
      this.recordStatus = status
    },
    submit() {
      if (this.recording === true) {
        this.$toast.fail( {
          message: '声音正在录制中'
        })
        return
      }

      // 提交录音
      if (this.content === '') {
        this.$dialog.alert( {
          message: '你还没有录制任何内容'
        })
        return
      }


      let self = this
      this.$toast.loading({
        duration: 0,
        message: '上传中',
        forbidClick: true
      })
      wx.uploadVoice({
        localId: self.content,
        isShowProgressTips: 0, // 默认为1，显示进度提示
        success: function (res) {
          var serverId = res.serverId; // 返回音频的服务器端ID
          classmateApi.sendAudioMsg({
            content: serverId,
            spaceId: self.spaceId,
            duration: self.recordTime,
          }).then(response => {
            if (response != null && response.code === 200) {
              console.log(response)
              self.$toast.success('提交成功')
              self.$router.replace({
                path:'/',
                query: {
                  id: self.spaceId
                }
              })
              return
            }
            self.$toast.fail('提交失败')
          })
        },
        fail: function () {
          self.$toast.fail('提交失败')
        }
      })
    },initSpaceId() {
      this.spaceId = this.$route.query.id

      if (strings.isBlank(this.spaceId)) {
        this.$router.replace("/auth")
      }
    },
    initSpaceInfo(jsApiList) {
      let spaceId = getUrlParam('id')

      if (jsApiList == null) {
        jsApiList = []
      }

      jsApiList.push(
          'updateTimelineShareData',
          'onMenuShareAppMessage',
          'onMenuShareQQ',
          'onMenuShareWeibo',
          'onMenuShareQZone'
      )

      if (strings.isBlank(spaceId)) {
        return
      }

      classmateApi.getSpaceInfo(spaceId).then(resp => {
        if (resp != null && resp.code === 200) {
          if (resp.data.spaceQueryVo == null) {
            Dialog.alert({
              message: '这段回忆已走远'
            }).then(() => {
              wx.closeWindow()
              return
            })
          }
          let userName = resp.data.spaceQueryVo.createUserName
          let currentUrl = window.location.href
          let url = getBaseUrl() + "/?id=" + spaceId
          let headImage = resp.data.userQueryVo.headImage
          classmateApi.initJsSdk(currentUrl).then(resp => {
            // eslint-disable-next-line no-debugger
            wx.config({
              // debug: true, // 开启调试模式,
              debug: false, // 开启调试模式,
              appId: resp.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
              timestamp: resp.timestamp, // 必填，生成签名的时间戳
              nonceStr: resp.nonceStr, // 必填，生成签名的随机串
              signature: resp.signature,// 必填，签名，见附录1
              jsApiList: jsApiList
            });
            wx.error(function () {
              Dialog.alert({
                title: '错误',
                message: '发生错误，请联系管理员'
              }).then(() => {
                wx.closeWindow()
                return
              })
            });
            console.log(userName)
            console.log(url)
            console.log(headImage)
            this.configShare(userName, url, headImage)
          })
        }
      })
    },
    configShare(userName, url, headImage) {
      wx.ready(function () {
        let self = this
        // 设置播放结束回调
        wx.onVoicePlayEnd({
          success: function (res) {
            console.log(res)
            clearInterval(self.timer)
            self.buttonText = self.recordTime
            self.setStatus(self.RECORDED)
          }
        });

        // 设置录音结束回调
        wx.onVoiceRecordEnd({
          complete: function (res) {
            self.content = res.localId;
          }
        });

        // 触发是否同意
        wx.startRecord({
          success:function(res) {
            console.log(res)
            wx.stopRecord({
              success: function (res) {
                console.log(res)
              }
            });
          }
        });

        if (userName === null || userName === 'null') {
          userName = '你的同学'
        }

        let shareData = {
          title: '回忆空间', // 分享标题
          desc: '这是一本电子同学录，记录了我们之间的点点滴滴，快来给写一笔', // 分享描述
          link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: headImage, // 分享图标
          type: 'link', // 分享类型,music、video或link，不填默认为link
        }
        wx.updateTimelineShareData({
          title: '回忆空间', // 分享标题
          link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: headImage, // 分享图标
          success: function () {
          }
        });
        wx.onMenuShareAppMessage({
          title: '回忆空间', // 分享标题
          desc: '这是一本电子同学录，记录了我们之间的点点滴滴，快来给写一笔', // 分享描述
          link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: headImage, // 分享图标
          type: 'link', // 分享类型,music、video或link，不填默认为link
          success: function () {
          }
        });
        wx.onMenuShareQQ(shareData);
        wx.onMenuShareWeibo(shareData);
        wx.onMenuShareQZone(shareData);


      })
    }
  },
  beforeRouteEnter(to, from, next) {
    let isiOS = isIosDevice()
    // XXX: 修复iOS版微信HTML5 History兼容性问题
    if (isiOS && to.path !== location.pathname) {
      // 此处不可使用location.replace
      location.assign(to.fullPath)
    } else {
      next()
    }
  },
  created() {
    this.initSpaceId()
    this.initSpaceInfo(['startRecord',
      'stopRecord',
      'onVoiceRecordEnd',
      'playVoice',
      'stopVoice',
      'onVoicePlayEnd',
      'uploadVoice'])
  }
}
</script>

<style scoped>

.classmate-audio-upload-button {
  width: 170px;
  height: 170px;
  margin-top: 15px;
  background-color: #68d0de;
  color: #fff;
  border: none;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.classmate-audio-remove-button {
  right: 80px;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  position: absolute;
  width: 30px;
  height: 30px;
}

</style>
