import axios from 'axios'
// eslint-disable-next-line no-unused-vars
import {getToken, getUrlParam} from "@/utils/web";
import strings from "@/utils/string";
// import strings from "@/utils/string";

export const baseUrl = process.env.VUE_APP_BASE_API

const service = axios.create({
    baseURL: baseUrl,
    timeout: 120000
})

service.interceptors.request.use(
    config => {
        config.headers['token'] = getToken()
        config.headers['Access-Control-Allow-Credentials'] = true
        return config
    }
)

service.interceptors.response.use(
    response => {
        const res = response.data
        const code = res.code
        const resultData = response.data.data;

        // 未授权，整个页面重定向
        if (code === 401) {
            let id = getUrlParam('id');
            if (strings.isBlank(id)) {
                window.location.href = "/wechat/redirect"
            }
            window.location.href = "/wechat/redirect?id=" + id
        }

        // if the custom code is not 20000, it is judged as an error.
        if (code !== 200) {
            // 5001: 请求参数校验异常
            if (code === 5001) {
                let errorMessage = ''
                if (resultData) {
                    if (resultData instanceof Array) {
                        const length = resultData.length
                        resultData.forEach((item, index) => {
                            console.log(index + '-' + item)
                            errorMessage += item
                            if (index !== (length - 1)) {
                                errorMessage += "<br/><br/>"
                            }
                        })
                    } else {
                        errorMessage += resultData
                    }
                } else {
                    errorMessage = '请求参数校验异常'
                }
                console.log(errorMessage)
            } else if (code === 50008 || code === 50012 || code === 50014) {
                // to re-login
                console.log('re-login')
            }
            // Promise.reject(new Error(res.message || 'Error'))
            // 当状态码不是200时，是否需要返回响应结果给调用方
            return res
        } else {
            return res
        }
    }, error => {
        if (error.response) {
            if (error.response.status === 401) {
                let id = getUrlParam('id');
                if (strings.isBlank(id)) {
                    window.location.href = "/auth"
                }
                window.location.href = "/auth?id=" + id
            }
        }
    }
)

export default service
