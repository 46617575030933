import request from "@/utils/request";

const authApi = {}

export const authToken = 'authToken';
export const currentUserId = 'currentUserId';

authApi.getAuthUrl = () => {
    return request({
        url: '/wechat/auth',
        method: 'get'
    })
}

authApi.getUserInfo = data => {
    return request({
        url: '/wechat/index?id=' + data.id + '&code=' + data.code,
        method: 'get',
        data
    })
}

export default authApi
