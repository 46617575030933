<template>
  <div class="auth">
    <!--    <h1>This is an auth page</h1>-->
  </div>
</template>

<script>

import strings from "@/utils/string";
import {baseUrl} from "@/utils/request";
import authApi, {authToken, currentUserId} from "@/api/auth";
import {Dialog} from "vant";
import Vue from 'vue'
import wx from 'weixin-js-sdk'
import {isIosDevice} from "@/utils/web";
Vue.use(Dialog)


export default {

  name: "auth",
  data() {
    return {
      authCode: '',
      spaceId: ''
    }
  },
  beforeRouteEnter (to, from, next) {
    let isiOS = isIosDevice()
    // XXX: 修复iOS版微信HTML5 History兼容性问题
    if (isiOS && to.path !== location.pathname) {
      // 此处不可使用location.replace
      location.assign(to.fullPath)
    } else {
      next()
    }
  },
  created() {
    let authCode = this.$route.query.code;
    let spaceId = this.$route.query.id;

    this.$toast.loading({
      duration: 0,
      message: '微信认证中',
      forbidClick: true
    })

    // 用户未授权直接访问，跳转到后台授权页面重定向
    if (strings.isBlank(authCode)) {
      if (strings.isBlank(spaceId)) {
        window.location.href = baseUrl + "/wechat/redirect"
        return
      }
      window.location.href = baseUrl + "/wechat/redirect?id=" + spaceId
      return
    }

    authApi.getUserInfo({
      code: authCode,
      id: spaceId
    }).then(resp => {
      // 清除loading
      this.$toast.clear()
      // 获取失败
      if (resp != null && resp.code === 200) {
        // 设置用户信息
        let result = resp.data;
        this.$cookies.set(currentUserId, result.userId)
        this.$cookies.set(authToken, result.token)

        // 重定向到首页
        this.$router.replace({
          path: '/',
          query: {
            id: result.spaceQueryVo.id
          }
        })
      } else {
        this.$dialog.alert({
          title: '错误',
          message: '认证服务异常，请联系管理员',
        }).then(() => {
          wx.closeWindow()
        });
      }
    })
  }
}
</script>

<style scoped>

</style>
