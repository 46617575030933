<template>
  <div id="index" style="width: 100%">
    <van-image :src="require('../assets/home1.jpg')"></van-image>
    <van-image :src="require('../assets/home2.jpg')"></van-image>
    <van-image :src="require('../assets/home3.jpg')"></van-image>
    <van-image :src="require('../assets/home4.jpg')"></van-image>
    <a href="https://beian.miit.gov.cn/" style="color: deepskyblue">备案号蜀ICP备2020036766号-1</a>
  </div>
</template>

<script>
import {Image as VanImage} from 'vant';
import Vue from 'vue'

Vue.use(VanImage)

export default {
  name: "index",
  beforeRouteEnter(to, from, next) {
    document.title = to.meta.title
    next()
  },
  beforeRouteLeave(to, from, next) {
    document.title = '我们的回忆'
    next()
  }
}
</script>

<style scoped>

</style>