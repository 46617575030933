<script>
import classmateApi from "@/api/classmate";
import wx from "weixin-js-sdk";
import {getBaseUrl, getUrlParam} from "@/utils/web";
import {Dialog} from "vant";
import Vue from 'vue'
import strings from "@/utils/string";

Vue.use(Dialog)

export default {
  name: "WechatConfig",
  initSpaceInfo(jsApiList) {
    let spaceId = getUrlParam('id')

    if (jsApiList == null) {
      jsApiList = []
    }

    jsApiList.push(
        'updateTimelineShareData',
        'onMenuShareAppMessage',
        'onMenuShareQQ',
        'onMenuShareWeibo',
        'onMenuShareQZone'
    )

    if (strings.isBlank(spaceId)) {
      return
    }

    classmateApi.getSpaceInfo(spaceId).then(resp => {
      if (resp != null && resp.code === 200) {
        if (resp.data.spaceQueryVo == null) {
          Dialog.alert({
            message: '这段回忆已走远'
          }).then(() => {
            wx.closeWindow()
            return
          })
        }
        let userName = resp.data.spaceQueryVo.createUserName
        let currentUrl = window.location.href
        let url = getBaseUrl() + "/?id=" + spaceId
        let headImage = resp.data.userQueryVo.headImage
        classmateApi.initJsSdk(currentUrl).then(resp => {
          // eslint-disable-next-line no-debugger
          wx.config({
            // debug: true, // 开启调试模式,
            debug: false, // 开启调试模式,
            appId: resp.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
            timestamp: resp.timestamp, // 必填，生成签名的时间戳
            nonceStr: resp.nonceStr, // 必填，生成签名的随机串
            signature: resp.signature,// 必填，签名，见附录1
            jsApiList: jsApiList
          });
          wx.error(function () {
            Dialog.alert({
              title: '错误',
              message: '发生错误，请联系管理员'
            }).then(() => {
              wx.closeWindow()
              return
            })
          });
          console.log(userName)
          console.log(url)
          console.log(headImage)
          this.configShare(userName, url, headImage)
        })
      }
    })
  },
  configShare(userName, url, headImage) {
    if (userName === null || userName === 'null') {
      userName = '你的同学'
    }

    wx.ready(function () {
      let shareData = {
        title: '回忆空间', // 分享标题
        desc: '这是一本电子同学录，记录了我们之间的点点滴滴，快来给写一笔', // 分享描述
        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: headImage, // 分享图标
        type: 'link', // 分享类型,music、video或link，不填默认为link
      }
      wx.updateTimelineShareData({
        title: '回忆空间', // 分享标题
        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: headImage, // 分享图标
        success: function () {
        }
      });
      wx.onMenuShareAppMessage({
        title: '回忆空间', // 分享标题
        desc: '这是一本电子同学录，记录了我们之间的点点滴滴，快来给写一笔', // 分享描述
        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: headImage, // 分享图标
        type: 'link', // 分享类型,music、video或link，不填默认为link
        success: function () {
        }
      });
      wx.onMenuShareQQ(shareData);
      wx.onMenuShareWeibo(shareData);
      wx.onMenuShareQZone(shareData);

    })
  }
}
</script>

<style scoped>

</style>
