import request from "@/utils/request";


const classmateApi = {

}


// 获取空间和用户信息
classmateApi.getSpaceInfo = id => {
    return request({
        url: '/wechat/space/info/'+id,
        method: 'get'
    })
}

// 从某个时间获取空间中的内容
classmateApi.getContentFromTime = data => {
    return request({
        url: 'wechat/content/getContentFromTime',
        method: 'post',
        data
    })
}

classmateApi.getContentByPage = data => {
    return request({
        url: 'wechat/content/getPageList',
        method: 'post',
        data
    })
}

// 初始化jssdk
classmateApi.initJsSdk = url => {
    return request({
        url: 'wechat/token/jsticket?url='+url,
        method: 'get'
    })
}

// 发送文字消息
classmateApi.sendTextMsg = data => {
    return request({
        url: 'wechat/content/saveTextContent',
        method: 'post',
        data
    })
}

// 发送图片消息
classmateApi.sendImageMsg = data => {
    return request({
        url: 'wechat/content/saveImageContent',
        method: 'post',
        data
    })
}

// 发送音频消息
classmateApi.sendAudioMsg = data => {
    return request({
        url: 'wechat/content/saveAudioContent',
        method: 'post',
        data
    })
}

classmateApi.uploadImageContent = data => {
    return request({
        url: 'wechat/content/uploadImageContent',
        method: 'post',
        data
    })
}

classmateApi.uploadVideoContent = data => {
    return request({
        url: 'wechat/content/uploadVideoContent',
        method: 'post',
        data,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
}

// 发送视频消息
classmateApi.sendVideoMsg = data => {
    return request({
        url: 'wechat/content/saveVideoContent',
        method: 'post',
        data
    })
}

// 撤回某条消息
classmateApi.recall = data => {
    return request({
        url: '/wechat/content/recall',
        method: 'post',
        data
    })
}

export default classmateApi
