<template>
  <div class="myVideo">
    <van-nav-bar
        left-arrow
        left-text="留下时光"
        @click-left="goBack"
        :fixed="true"
        z-index="999"
        class="classmate-tab-bar"
    >
      <template #right>
        <van-icon :name="require('@/assets/icon/logo@3x.png')" size="80" />
      </template>
    </van-nav-bar>
    <p class="classmate-upload-tips" style="margin-top: 60px;">每段视频不能超过50MB</p>
    <div style="width: 90%; margin-left: 20px">
      <van-button round type="info" v-if="this.content !== ''" @click="remove" color="white" class="classmate-audio-remove-button" :icon="require('@/assets/icon/cross.svg')" >
      </van-button>

      <video :src="url" id="video" style="width: 100%; height: auto;  -webkit-border-radius: 10px;" :poster="require('@/assets/poster.jpg')" v-if="content !== ''"
             preload="true" x-webkit-airplay="true" webkit-playsinline="true" playsinline="true" x5-video-player-type="h5" controls = "false"></video>
    </div>
    <div class="classmate-upload-button-grid" v-if="content===''">
      <van-uploader style="margin: 0 auto" upload-icon="video-o"
                    @oversize="onOverSize"
                    :max-size="50 * 1024 * 1024"
                    :before-read="beforeRead"
                    :after-read="afterRead"
                    accept="video/*"
      />
    </div>
    <br/>


    <van-button type="primary" color="#68d0de" class="classmate-commit-button" @click="submit">提 交 时 光</van-button>
  </div>
</template>

<script>
import {isIosDevice} from "@/utils/web";
import Vue from 'vue';
import { Uploader } from 'vant';
import {Dialog} from 'vant';
import strings from "@/utils/string";
import WechatUtils from "@/components/WechatUtils";
import classmateApi from "@/api/classmate";
import $ from 'jquery'
import obsClient, {accessUrl} from "@/utils/obs";
import {currentUserId} from "@/api/auth";


Vue.use(Uploader);
Vue.use(Dialog);

export default {
  name: "myVideo",
  beforeRouteEnter (to, from, next) {
    let isiOS = isIosDevice()
    // XXX: 修复iOS版微信HTML5 History兼容性问题
    if (isiOS && to.path !== location.pathname) {
      // 此处不可使用location.replace
      location.assign(to.fullPath)
    } else {
      next()
    }
  },
  computed: {
    playsinline () {
      let ua = navigator.userAgent.toLocaleLowerCase()
      // x5内核
      if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
        return false
      } else {
        // ios端
        return true
      }
    }
  },
  data() {
    return{
      content:'',
      url: '',
      spaceId: 0
    }
  },
  created() {
    this.initSpaceId()
    WechatUtils.initSpaceInfo()
  },
  methods: {
    remove() {
      this.$dialog.confirm({
        message: '你的视频还没有上传，是否删除？'
      }).then(() => {
        this.content = ''
      })
    },
    beforeRead(file) {
      console.log(file.type)
      if (file.type.indexOf('video') === -1) {
        this.$toast.fail( {
          message: '上传视频格式有误'
        })
        return false;
      }
      return true;
    },
    videoFullScreen() {
      let width = document.documentElement.clientWidth;
      let height = document.documentElement.clientHeight;
      document.getElementById('video_content').style.height = width + 'px';
      document.getElementById('video_content').style.width = height + 'px';
    },
    onPlayerCanplay (player) {
      console.log('player Canplay!', player)
      const ua = navigator.userAgent.toLocaleLowerCase();
      // eslint-disable-next-line no-debugger
      debugger
      // x5内核
      if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
        $('body').find('video').attr('x-webkit-airplay', true).attr('x5-playsinline', true).attr('webkit-playsinline', true).attr('playsinline', true).attr('x5-video-player-fullscreen', true)
      } else {
        // ios端
        $('body').find('video').attr('webkit-playsinline', 'true').attr('playsinline', 'true').attr('x5-video-player-fullscreen', 'true')
      }
    },
    afterRead: function (file) {
      console.log(file)
      const formData = new FormData()
      formData.append("file", file.file)
      formData.append("spaceId", this.spaceId)
      const self = this
      this.$toast.loading({
        duration: 0,
        message: "上传中",
        forbidClick: true
      })

      const userId = this.$cookies.get(currentUserId)
      const key = 'space/' + self.spaceId + "/user/" + userId + "/" + file.file.name
      console.log(key)
      obsClient.putObject({
        Bucket: 'classmate',
        Key: key,
        Body: file.file
      }, function (err, result) {
        if (err) {
          console.error('Error-->' + err);
        } else {
          if (result.CommonMsg.Status === 200) {
            console.log(accessUrl + key)
            self.content = key;
            self.url = accessUrl + key;
            self.$toast.clear()
          }
          console.log('Status-->' + result.CommonMsg.Status);
          self.$toast.clear()
        }
      })
    },
    initSpaceId() {
      this.spaceId = this.$route.query.id

      if (strings.isBlank(this.spaceId)) {
        this.$router.replace("/auth")
      }
    },
    onOverSize() {
      this.$toast.fail( {
        message: '不能上传大于50MB的视频'
      })
    },
    submit() {
      if (this.content === '') {
        this.$toast.fail('你还没有上传视频')
        return
      }

      const data = {
        spaceId: this.spaceId,
        content: this.content
      }
      this.$toast.loading({
        duration: 0,
        message: '提交中',
        forbidClick: true
      })
      const self = this
      classmateApi.sendVideoMsg(data).then(response => {
        if (response.code === 200) {
          this.$toast.success('提交成功')
          self.$router.replace({
            path:'/',
            query: {
              id: self.spaceId
            }
          })
          return
        }
        this.$toast.fail('提交失败')
      })
    },
    goBack() {
      if (!strings.isBlank(this.content)) {
        this.$dialog.confirm({
          message: '你的视频还没有上传，是否离开？'
        }).then(() => {
          this.$router.replace({
            path:'/',
            query: {
              id: this.$route.query.id
            }
          })
        })
        return
      }
      this.$router.replace({
        path:'/',
        query: {
          id: this.$route.query.id
        }
      })
    }
  }
}
</script>

<style scoped>

.classmate-upload-button-grid {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  border: medium dashed lightgray;
  -webkit-border-radius: 20px;
  height: 200px;
  width: 85%;
}

/*／*视频旋转*!*/
.video_box_rotate{
}

.video_box_rotate{
  transform: rotate(90deg)
}


.classmate-audio-remove-button {
  right: 40px;
  top: 110px;
  -webkit-box-shadow: #666 0px 0px 10px;
  position: absolute;
  width: 30px;
  height: 30px;
  z-index: 99;
}

</style>
