<template>
  <div class="photo">
    <van-nav-bar
        left-arrow
        left-text="留影"
        @click-left="goBack"
        :fixed="true"
        class="classmate-tab-bar"
        z-index="999"
    >
      <template #right>
        <van-icon :name="require('@/assets/icon/logo@3x.png')" size="80" />
      </template>
    </van-nav-bar>
    <van-list style="margin-top: 60px">
      <p class="classmate-upload-tips">单张图片需要小于30MB</p>

      <div class="classmate-upload-image-grid" v-for="item in toUploadList" :key="item.id">
        <van-button round class="classmate-upload-cancel-button" color="white" v-bind:class="item.id"
                    @click="cancel(item.id)" :icon="require('@/assets/icon/cross.svg')">
        </van-button>

        <van-image :src="item.url" radius="15px"
                   style="width: 100%; height: 100%;" fit="cover"
                   @click="preview(item.url)">
        </van-image>
      </div>
    </van-list>

    <div class="classmate-upload-button-grid" v-if="toUploadList.length <= maxUploadNum">
      <button class="classmate-upload-button" @click="uploadImage()">
        <svg class="classmate-upload-button-background-image" aria-hidden="true">
          <use xlink:href="#iconcamera"></use>
        </svg>

      </button>
    </div>
    <br/>
    <van-button type="primary" color="#68d0de" class="classmate-commit-button" @click="submit">提 交 留 影</van-button>
    <br/>
    <br/>
  </div>
</template>

<script>
import Vue from 'vue';
import {Button, Icon, Image, Uploader} from 'vant';
import WechatUtils from "@/components/WechatUtils";
import wx from 'weixin-js-sdk'
import strings from "@/utils/string";
import classmateApi from "@/api/classmate";
import { Overlay } from 'vant';
import {isIosDevice} from "@/utils/web";

Vue.use(Overlay);

Vue.use(Icon)
Vue.use(Uploader);
Vue.use(Image)
Vue.use(Button)

export default {
  name: "photo",
  created() {
    // 初始化微信方法
    this.initSpaceId()
    WechatUtils.initSpaceInfo(['chooseImage', 'uploadImage', 'previewImage'])
  },
  data() {
    return {
      // 待上传的图片列表,格式{"id":“微信mediaId”, "content":"图片base64编码"}
      maxUploadNum: 9,
      toUploadList: [],
    }
  },
  beforeRouteEnter (to, from, next) {
    let isiOS = isIosDevice()
    // XXX: 修复iOS版微信HTML5 History兼容性问题
    if (isiOS && to.path !== location.pathname) {
      // 此处不可使用location.replace
      location.assign(to.fullPath)
    } else {
      next()
    }
  },
  methods: {
    preview(url) {
      let array = []
      for (const i in this.toUploadList) {
        array.push(this.toUploadList[i].url)
      }

      wx.previewImage({
        current: url,
        urls: array
      })
    },
    initSpaceId() {
      this.spaceId = this.$route.query.id

      if (strings.isBlank(this.spaceId)) {
        this.$router.replace("/auth")
      }
    },
    goBack() {
      if (this.toUploadList.length > 0) {
        this.$dialog.confirm({
          message: '你的图片还没上传，确认返回？'
        }).then(() => {
          this.$router.replace({
            path:'/',
            query: {
              id: this.$route.query.id
            }
          })
        })
        return
      }

      this.$router.replace({
        path:'/',
        query: {
          id: this.$route.query.id
        }
      })
    },
    submit() {
      if (Object.keys(this.toUploadList).length === 0) {
        this.$dialog.alert({
          title: '留影',
          message: '你还没留下任何东西',
        }).then(() => {
          // on close
        });
        return
      }

      this.$toast.loading({
        duration: 0,
        message: '提交中',
        forbidClick: true
      })

      let imgArr = []
      for (let i in this.toUploadList) {
        imgArr.push(this.toUploadList[i].content)
      }

      this.$toast.loading({
        duration: 0,
        message: '提交中',
        forbidClick: true
      })

      let self = this
      classmateApi.sendImageMsg({
        contents: imgArr,
        spaceId: self.spaceId
      }).then(resp => {
        if (resp != null && resp.code === 200) {
          this.$toast.success('提交成功')
          this.$router.replace({
            path: '/',
            query: {
              id: self.spaceId
            }
          })
          return
        }

        this.$toast.fail('提交失败')
      })
    },
    uploadImage() {
      let self = this;
      this.$toast.loading({
        duration:0,
        message: '上传中',
        forbidClick: true
      })
      wx.chooseImage({
        // 考虑兼容性，每次只能上传1张图片
        count: 1,
        sizeType: ['compressed', 'original'],
        success: function (result) {
          // 2.先上传图片
          wx.uploadImage({
            localId: result.localIds[0],
            isShowProgressTips: 0,
            success: function (uploadRes) {
              const param = {
                content: uploadRes.serverId,
                spaceId: self.spaceId,
              }
              classmateApi.uploadImageContent(param).then(resp => {
                if (resp != null && resp.code === 200) {
                  wx.getLocalImgData({
                    localId: result.localIds[0],
                    success: function (res) {
                      self.$toast.success('上传成功')
                      console.log(res)
                      // 安卓设备不支持base64预览
                      let preViewContent = resp.data.url
                      if (isIosDevice()) {
                        // ios设备直接base64预览
                        preViewContent = res.localData
                      }
                      self.toUploadList.push({
                        id: result.localIds[0],
                        url: preViewContent,
                        content: resp.data.content,
                      })
                    }
                  })
                } else {
                  self.$toast.fail('上传失败')
                }
              })
            }
          })
        },
        fail: function (err) {
          console.log('失败==',err)
          self.$toast.clear()
        },
        complete: function(cres){
          console.log('完成==', cres)
          if (cres.errMsg != null && cres.errMsg === "chooseImage:cancel") {
            self.$toast.clear()
          }
        }
      })
    },
    removeList(array, id) {
      let index = -1;
      for (let i in array) {
        if (array[i].id === id) {
          index = i
          break
        }
      }
      if (index >= 0) {
        array.splice(index, 1)
      }
    },
    cancel(id) {
      this.$dialog.confirm({
        title: '取消上传',
        message: '你确定要取消上传这张图片吗？'
      }).then(() => {
        this.removeList(this.toUploadList, id)
      })
    }
  }
}
</script>

<style scoped>


.classmate-upload-button-grid {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  border: medium dashed lightgray;
  -webkit-border-radius: 20px;
  height: 200px;
  width: 85%;
}

.classmate-upload-image-grid {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  -webkit-border-radius: 20px;
}

.classmate-upload-button-background-image {
  width: 40px;
  height: 40px;
  color: lightgray;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.classmate-upload-cancel-button {
  margin-top: 10px;
  float: right;
  margin-bottom: -50px;
  z-index: 9;
  margin-right: 20px;
  background-color: #fefdfb;
  border: none;
  -webkit-box-shadow: #666 0px 0px 10px;
  height: 30px;
  width: 30px;
}

.classmate-upload-button {
  border: none;
  background-color: #fff;
  width: 100%;
  height: 100%;
  -webkit-border-radius: 20px;
}

</style>
