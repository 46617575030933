<template>
  <div class="write">
    <van-nav-bar
        left-arrow
        left-text="写下感想"
        @click-left="goBack"
        :fixed="true"
        class="classmate-tab-bar"
    >
      <template #right>
        <van-icon :name="require('@/assets/icon/logo@3x.png')" size="80" />
      </template>
    </van-nav-bar>
    <div style="width: 100%; height: 80%; position: absolute; margin-top: 40px;">
      <label>
        <textarea class="classmate-write-content"
                  placeholder="请记录此刻的想法（2500个字符以内）"
                  maxlength="2500"
                  v-model="content"></textarea>
      </label>

      <van-button type="primary" color="#68d0de" class="classmate-commit-button" @click="submit()">提 交 感 想</van-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {Button} from 'vant';
import strings from "@/utils/string";
import classmateApi from "@/api/classmate";
import WechatUtils from "@/components/WechatUtils";
import {isIosDevice} from "@/utils/web";

Vue.use(Button)

export default {
  name: "write",
  data() {
    return {
      content: '',
      spaceId: '',
    }
  },
  beforeRouteEnter (to, from, next) {
    let isiOS = isIosDevice()
    // XXX: 修复iOS版微信HTML5 History兼容性问题
    if (isiOS && to.path !== location.pathname) {
      // 此处不可使用location.replace
      location.assign(to.fullPath)
    } else {
      next()
    }
  },
  created() {
    this.initSpaceId()
    WechatUtils.initSpaceInfo()
  },
  methods: {
    goBack() {
      // eslint-disable-next-line no-debugger
      // debugger
      if (!strings.isBlank(this.content)) {
        this.$dialog.confirm({
          message: '你的感想还没提交，确认返回？'
        }).then(() => {
          this.$router.replace({
            path:'/',
            query: {
              id: this.$route.query.id
            }
          })
        })
        return
      }

      this.$router.replace({
        path:'/',
        query: {
          id: this.$route.query.id
        }
      })
    },
    initSpaceId() {
      this.spaceId = this.$route.query.id

      if (strings.isBlank(this.spaceId)) {
        this.$router.replace("/auth")
      }
    },
    submit() {
      if (strings.isBlank(this.content)) {
        this.$dialog.alert({
          title: '写下感想',
          message: '你还没写下你的感想呢',
        }).then(() => {
          // on close
        });
        return
      }

      this.$toast.loading({
        duration: 0,
        message: '提交中',
        forbidClick: true,
      })

      let self = this
      classmateApi.sendTextMsg({
        content: this.content,
        spaceId: this.spaceId
      }).then(resp => {
        if (resp != null && resp.code === 200) {
          console.log(resp)
          this.$toast.success('提交成功')
          this.$router.replace({
            path:'/',
            query: {
              id: self.spaceId
            }
          })
          return
        }
        this.$toast.fail('提交失败')
      })
    },
    textChange(data) {
      this.content = data
    }
  }
}
</script>

<style scoped>
.classmate-write-content {
  resize: none;
  width: 75%;
  height: 90%;
  margin-top: 20px;
  padding: 20px;
  text-align: left;
  margin-right: 20px;
  margin-left: 20px;
  overflow:hidden;
  /*background: #68d0de;*/
  -webkit-border-radius: 20px;
  border: medium solid #68d0de;
  line-height: 1.5;
  font-size: 14px;
}

</style>
