import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueCookies from 'vue-cookies'
import { Dialog } from 'vant';
import {Toast} from "vant";
import 'vant/lib/index.less'
// import vConsole from 'vconsole'
// Vue.prototype.$vConsole= new vConsole()

Vue.config.productionTip = false

// 全局设置cookie
Vue.use(VueCookies)
Vue.use(Dialog)
Vue.use(Toast)
// Vue.$cookies.config('/')

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

