import ObsClient from 'esdk-obs-browserjs'

const ak = process.env.VUE_APP_ACCESS_KEY_ID
const sk = process.env.VUE_APP_SECRET_ACCESS_KEY
const server = process.env.VUE_APP_SERVER
export const accessUrl = process.env.VUE_APP_ACCESS_URL

const obsClient = new ObsClient({
    access_key_id: ak,
    secret_access_key: sk,
    server : server
})

export default obsClient
