import {authToken} from "@/api/auth";

export function getToken() {
    return getCookie(authToken)
}

export function getUrlParam(name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
    let r = window.location.search.substr(1).match(reg);  //匹配目标参数
    if (r != null) return unescape(r[2]);
    return null; //返回参数值
}

export function getBaseUrl() {
    let currentUrl = window.location.href
    let pathName = window.location.pathname
    let url = ''
    if (pathName === '/') {
        url = currentUrl.substr(0, currentUrl.lastIndexOf(pathName))
    } else {
        url = currentUrl.substr(0, currentUrl.indexOf(pathName))
    }
    return url
}

export function isWechatBrowser() {
    return (/micromessenger/.test(navigator.userAgent.toLocaleLowerCase()));
}

// 是否是ios设备
export function isIosDevice() {
    return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
}

// 是否是安卓设备
export function isAndroidDevice() {
    return navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1;
}

export function getCookie(c_name)
{
    if (document.cookie.length>0)
    {
        let c_start=document.cookie.indexOf(c_name + "=")
        if (c_start!=-1)
        {
            c_start=c_start + c_name.length+1
            let c_end=document.cookie.indexOf(";",c_start)
            if (c_end==-1) c_end=document.cookie.length
            return unescape(document.cookie.substring(c_start,c_end))
        }
    }
    return ""
}