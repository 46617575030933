import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import write from "@/views/write";
import photo from "@/views/photo";
import myAudio from "@/views/myAudio";
import video from "@/views/myVideo";
import auth from "@/views/auth";
import index from "@/views/index"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/index',
    name: 'index',
    component: index,
    meta: {
      title: '本忆商务公司'
    }
  },
  {
    path: '/write',
    name: '写下感想',
    component: write
  },
  {
    path: '/photo',
    name: '留影',
    component: photo
  },
  {
    path: '/myAudio',
    name: '留声',
    component: myAudio
  },
  {
    path: '/video',
    name: '留下时光',
    component: video
  },
  {
    path: '/auth',
    name: '认证页面',
    component: auth
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
